<template>
  <div id="compbox">
    <div class="hadheight"></div>
    <Header />
    <!-- 支付成功 -->
    <div class="compbox-sec1 base100">
        <div class="basecont">
            <div class="cont">
                <div class="f_tit">
                    <a href="javascript:;" class="logo"><img src="../../assets/images/zhifucimgfa1.png" alt=""></a>
                </div>
                <div class="f_dui">
                    <i><img src="../../assets/images/zhifuchengwfiduiic1.png" alt=""></i>
                    <span>支付成功！</span>
                </div>
                <div class="f_text">
                    <p>
                        用户名 您好，
                    </p>
                    <p>
                        感谢您选择Habitos. 本次消费金额为123,860元，订单号：821081186763。
                    </p>
                    <p>
                        未来几个工作日内，个人中心会及时更新您本次消费订单的最新进度。
                    </p> 
                </div>
                <div class="f_but">
                    <router-link to="/gaizhuangche" class="hm-but">
                        <span>返回首页</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </router-link>
                    <router-link to="/my/myorder" class="hm-but">
                        <span>查看订单</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "Complete",
  components: {
    Header,
    Footer
  },
  data(){
    return {
     
    }
  },
  created:function(){
      
  },
  methods: {
    
  }
}
</script>
